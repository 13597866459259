import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import API from '../../api/axios';
export default defineComponent({
  name: "NewsDetail",
  setup: function setup() {
    var route = useRoute(); // console.log("---", route.query.id);

    var news_id = ref();
    news_id.value = route.query.id;
    var bannerList = ref([{
      url: require("../../assets/banner.jpg")
    } // {
    //   url: require("../../assets/banner2.jpg"),
    // },
    ]);
    var dataList = ref({});

    var getData = function getData() {
      API({
        url: 'https://picbook.bcepd.cn/d-bcepd/cmpcms/userfree/load',
        method: 'POST',
        params: {
          news_id: news_id.value
        }
      }).then(function (res) {
        console.log("res", res.data);
        dataList.value = res.data.r;
      });
    };

    getData();

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    return {
      bannerList: bannerList,
      dataList: dataList,
      handlebackTop: handlebackTop
    };
  }
});